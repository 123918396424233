export const AccountFields = [
  { field: "code", title: "Code", type: "string" },
  {
    field: "name",
    title: "Name",
    type: "string",
  },
  {
    field: "country",
    title: "Country",
    type: "string",
  },
  {
    field: "country_code",
    title: "Country Code",
    type: "string",
  },
  {
    field: "bank",
    title: "Bank",
    type: "string",
  },
  {
    field: "type",
    title: "Type",
    type: "string",
  },
  {
    field: "active",
    title: "Active",
    type: "boolean",
  },
  {
    field: "created",
    title: "Created",
    type: "date",
  },
  {
    field: "updated",
    title: "Updated",
    type: "date",
  },
];
